<template>
  <component :is="patientData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="primary"
      :show="patientData === undefined"
    >
      <div class="alert-body">
        No patient selected to edit. Check
        <b-link
          class="alert-link"
          :to="{ name: 'patient-list'}"
        >
          Patient List
        </b-link>
        for more patients.
      </div>
    </b-alert>

    <b-tabs
      v-if="patientData"
      v-model="tabIndex"
      pills
    >
      <!-- Tab: Basic -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Basic</span>
        </template>
        <patient-edit-basic-info
          :patient-data="patientData"
          :tab-index.sync="tabIndex"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <patient-edit-details-info
          :patient-data="patientData"
          class="mt-2 pt-75"
        />
      </b-tab>

    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import PatientStoreModule from '@/store/main/PatientStoreModule'
import PatientEditBasicInfo from './PatientEditBasicInfo.vue'
import PatientEditDetailsInfo from './PatientEditDetailsInfo.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    PatientEditBasicInfo,
    PatientEditDetailsInfo,
  },
  setup() {
    const PATIENT_STORE_MODULE_NAME = 'patient-store'
    // Register module
    if (!store.hasModule(PATIENT_STORE_MODULE_NAME)) store.registerModule(PATIENT_STORE_MODULE_NAME, PatientStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PATIENT_STORE_MODULE_NAME)) store.unregisterModule(PATIENT_STORE_MODULE_NAME)
    })

    const patientData = ref(null)
    const tabIndex = ref(1)

    if (router.currentRoute.params.id === undefined || router.currentRoute.params.id === null) {
      patientData.value = undefined
      return {
        patientData,
      }
    }

    store.dispatch('patient-store/getPatientById', { id: router.currentRoute.params.id })
      .then(response => { patientData.value = response.data.Data })
      .catch(error => {
        if (error.response.status === 404) {
          patientData.value = undefined
        }
      })

    return {
      patientData,
      tabIndex,
    }
  },
}
</script>

<style>

</style>
