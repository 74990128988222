<template>
  <div>

    <!-- Patient Info: Input Fields -->
    <b-form>
      <!-- Header: Contact Info -->
      <div class="d-flex">
        <feather-icon
          icon="SendIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Contact Info
        </h4>
      </div>

      <b-row class="mt-1">
        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="patientData.Email"
              type="email"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Job -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Job"
            label-for="job"
          >
            <b-form-input
              id="job"
              v-model="patientData.Job"

            />
          </b-form-group>
        </b-col>

      </b-row>

      <!-- Header: Personal Info -->
      <div class="d-flex mt-2">
        <feather-icon
          icon="UserPlusIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Personal Info
        </h4>
      </div>

      <b-row class="mt-1">
        <!-- Field: NationalId -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="National Id"
            label-for="nationalId"
          >
            <b-form-input
              id="nationalId"
              v-model="patientData.NationalId"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Gender -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Gender"
            label-for="gender"
            label-class="mb-1"
          >
            <b-form-radio-group
              id="gender"
              v-model="patientData.Gender.Id"
              :options="genderOptions"
              value="GenderName"
            />
          </b-form-group>
        </b-col>

        <!-- Field: SocialStatus -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Social Status"
            label-for="gender"
            label-class="mb-1"
          >
            <b-form-radio-group
              id="socialStatus"
              v-model="patientData.SocialStatus.Id"
              :options="socialStatusOptions"
              value="SocialStatusName"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Header: Address Info -->
      <div class="d-flex mt-2">
        <feather-icon
          icon="MapPinIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Address Info
        </h4>
      </div>

      <b-row class="mt-1">
        <!-- Field: Address Line -->
        <b-col
          cols="12"
          md="12"
          lg="8"
        >
          <b-form-group
            label="Address Line"
            label-for="address-line"
          >
            <b-form-input
              id="address-line"
              v-model="patientData.Address"
              dir="auto"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <!-- Field: Country -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Country"
            label-for="country"
          >
            <b-form-input
              id="country"
              v-model="patientData.Country"
              placeholder=""
            />
          </b-form-group>
        </b-col>

        <!-- Field: City -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="City"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="patientData.City"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Area -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Area"
            label-for="area"
          >
            <b-form-input
              id="area"
              v-model="patientData.Area"
              placeholder=""
            />
          </b-form-group>
        </b-col>

      </b-row>
       <!-- Chronic Diseases -->
        <div class="d-flex mt-2">
            <feather-icon icon="MapPinIcon"
                        size="19" />
            <h4 class="mb-0 ml-50">
                Chronic Diseases
            </h4>
          </div>
       <b-row class="mt-1">
        <b-col cols="12"
                 md="4">
        <b-form-group label="Chronic Diseases"
                    label-for="chronicDiseases">
            <v-select v-model="selectedChronicDiseases"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="chronicDiseasesOptions"
                    :reduce="val => val.value"
                    label="label"
                    :clearable="false"
                    input-id="chronicDiseases"
                    multiple  />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <div class="d-flex mt-3">
      <b-button
        variant="primary"
        class="mr-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="onUpdateDetailsInfoForPatient(patientData)"
      >
         Save Changes
      </b-button>
      <b-button
        variant="outline-secondary"
        type="reset"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="$router.push({ name: 'patient-list' })"
      >
        Cancel
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BFormRadioGroup,
} from 'bootstrap-vue'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import {
  ref, onUnmounted, computed, watch,
} from '@vue/composition-api'
import { email } from '@validations'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
// StoreModule
import patientStoreModule from '@/store/main/PatientStoreModule'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    patientData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      email,
    }
  },
  setup(props) {
    const DOCTOR_APP_STORE_MODULE_NAME = 'patient-store'
    // Register module
    if (!store.hasModule(DOCTOR_APP_STORE_MODULE_NAME)) store.registerModule(DOCTOR_APP_STORE_MODULE_NAME, patientStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DOCTOR_APP_STORE_MODULE_NAME)) store.unregisterModule(DOCTOR_APP_STORE_MODULE_NAME)
    })

    const resolvePatientVariant = () => {
      switch (Math.floor(Math.random() * (4 - 0)) + 0) {
        case 0: return 'primary'
        case 1: return 'warning'
        case 2: return 'success'
        case 3: return 'info'
        case 4: return 'danger'
        default: return 'primary'
      }
    }

    const genderOptions = [
      { text: 'Male', value: '1' },
      { text: 'Female', value: '2' },
    ]
    const socialStatusOptions = [
      { text: 'Single', value: '1' },
      { text: 'Married', value: '2' },
      { text: 'Divorced', value: '3' },
    ]

    store.dispatch('patient-store/getAllChronicDiseases').then().catch(() => {
      // console.log('Error fetching Specializations list for dropdown')
    })
    const chronicDiseasesOptions = computed(() => store.state['patient-store'].chronicDiseases)

    const selectedChronicDiseases = ref(props.patientData.ChronicDiseases.map(v => v.Id))
    // watch(selectedChronicDiseases, (selection, prevSelection) => {
    watch(selectedChronicDiseases, selection => {
      // eslint-disable-next-line no-param-reassign
      props.patientData.ChronicDiseases.Id = selection
      // console.log(selection)
      // console.log(prevSelection)
    }, { immediate: true })

    const toast = useToast()
    function onUpdateDetailsInfoForPatient(patientData) {
      store.dispatch('patient-store/updatePatientDetailsInfo', {
        Id: patientData.Id,
        NationalId: patientData.NationalId,
        Gender: patientData.Gender.Id,
        SocialStatus: patientData.SocialStatus.Id,
        Email: patientData.Email,
        Job: patientData.Job,
        Address: patientData.Address,
        Country: patientData.Country,
        City: patientData.City,
        Area: patientData.Area,
        ChronicDiseases: patientData.ChronicDiseases.Id,
      })
        .then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Operation Done',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'You have successfully update patient!',
            },
          })
          this.$router.push({ name: 'patient-list' })
          // this.$router.go(-1)
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Operation Failed',
              icon: 'XIcon',
              variant: 'danger',
              text: 'Error happened when updating new patient!',
            },
          })
        })
    }

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.patientData.avatar = base64
    })
    // console.log(props.patientData)
    return {
      resolvePatientVariant,
      avatarText,
      genderOptions,
      socialStatusOptions,
      chronicDiseasesOptions,
      selectedChronicDiseases,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,

      // Function
      onUpdateDetailsInfoForPatient,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
